<template>
	<div id="container">
		<header-navbar
			class="fixed"
			bgTheme="bg-transparent-font-white"
		></header-navbar>

		<div class="bg-1">
			<img :src="pageImage['bg-1']" alt="" />
			<img class="gif-image" :src="pageImage['bg-1-gif']" alt="" />
		</div>

		<img :src="pageImage['bg-2']" alt="" />
		<img :src="pageImage['bg-3']" alt="" />
		<img :src="pageImage['bg-4']" alt="" />

		<!-- 底部 -->
		<floor-bg class="floor-bg" bgTheme="floor-bg-black"></floor-bg>
	</div>
</template>

<script setup>
import { computed, reactive } from "vue";
const pageImage = computed(() => {
	const gifs = ["bg-1-gif"];
	const imgs = ["bg-1", "bg-2", "bg-3", "bg-4"];

	const result = {};

	for (let i = 0; i < imgs.length; i++) {
		const item = imgs[i];
		result[
			item
		] = require(`@/assets/images/page-body/intelligent-hardware-GUI/${item}.png`);
	}
	for (let i = 0; i < gifs.length; i++) {
		const item = gifs[i];
		result[
			item
		] = require(`@/assets/images/page-body/intelligent-hardware-GUI/${item}.gif`);
	}

	return result;
});
</script>

<style lang="scss" scoped>
#container {
	width: 100%;
	display: flex;
	align-content: center;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
	background-color: black;

	.bg-1 {
		width: 1920px;
		height: auto;
		position: relative;

		.gif-image {
			position: absolute;
			bottom: 130px;
			left: 680px;
			width: 550px;
			height: 700px;
		}
	}

	.floor-bg {
		margin-top: 120px;
	}
}
</style>
